<template>
  <v-data-table
    v-model="select"
    :item-key="itemKey"
    show-select
    :headers="headers"
    :loading="loading"
    :server-items-length="maxRecords"
    :items="data"
    :options.sync="options"
    :search="search"
    :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
    :disable-sort="disableSort"
  >
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    query: Object,
    queryName: String,
    queryParams: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    search: String,
    selected: Array,
    itemKey: String
  },
  data: () => ({
    loading: false,
    options: {},
    maxRecords: 0,
    data: [],
    select: []
  }),
  watch: {
    options(value) {
      this.$emit('update:options', value)
      this.refreshGetData()
    },
    search() {
      this.options.page = 1
      this.refreshGetData()
    },
    queryParams() {
      this.refreshGetData()
    },
    select(value) {
      this.$emit('update:selected', value)
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    refreshGetData() {
      setTimeout(() => {
        this.getData()
      }, 500)
    },
    async getData() {
      try {
        const variables = Object.assign(
          {},
          { page: this.options.page || 1 },
          { pageSize: this.options.itemsPerPage || 10 },
          { sort: this.options.sortBy },
          { sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC')) },
          this.search ? { search: this.search } : null,
          this.queryParams
        )

        this.loading = true
        const { data } = await this.$apollo.query({
          query: this.query,
          variables
        })

        this.data = data?.[this.queryName].data
        this.maxRecords = data?.[this.queryName].count
      } catch (err) {
        console.log('Failed to get data', err.message)
      } finally {
        this.loading = false
        this.$emit('update:maxRecords', this.maxRecords)
        this.$emit('update:page', this.options.page)
        this.$emit('update:itemsPerPage', this.options.itemsPerPage)
      }
    }
  }
}
</script>
